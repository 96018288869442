<template>
  <account type="wdkc" tabText="课程列表">
    <el-skeleton :rows="10" animated v-show="isLoading"/>
    <div v-show="!isLoading">
      <div v-if="dataSource.length === 0" class="notdata">
        <el-empty description="暂时没有数据"></el-empty>
      </div>
      <template v-else>
        <table class="course_table table">
          <tbody>
          <template v-for="item in dataSource">
            <account-course-history
              :item="item"
              :key="item.id"
              v-copytouch.native="()=>{item.isEffective === hasEffective.VALID? routerToCaseDetail(item):alertMesg(item)}"></account-course-history>
          </template>

          </tbody>
        </table>
        <!--分页区域-->
        <p-pagination-base
          :currentPage.sync="currentPage"
          :pageSize.sync="pageSize"
          :total="total"
          @currentPageChange="handleCurrentChange"/>
      </template>
    </div>
    <el-backtop :bottom="100" :visibility-height="50"></el-backtop>
  </account>
</template>

<script>

  import AccountCourseHistory from './modules/AccountCourseHistory'
  import Account from '@views/account/Account'
  import PImage from '@/components/project/image/PImage'
  import { getMyCourses } from '@api/myCourses'
  import { getEffectiveTypeStatus, hasEffective } from '@/enum/effectiveType'
  import PPaginationBase from '@/components/project/pagination/PPaginationBase'

  export default {
    name: 'AccountCourse',
    components: { PPaginationBase, AccountCourseHistory, PImage, Account },
    data() {
      return {
        currentPage: 1,
        pageSize: 10,
        total: 10,
        dataSource: [],
        hasEffective: hasEffective,
        isLoading: false
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      async loadData() {
        this.isLoading = true
        let params = {
          queryPage: this.currentPage,
          querySize: this.pageSize
        }
        let res = await getMyCourses(params)
        if (!res.success) {
          this.$message.error(res.message)
          return
        }
        this.dataSource = res.list.map((item) => {
          let rtnObj = {
            id: item.id,
            courseId: item.courseId,
            coverUrl: item.coverUrl,
            name: item.name,
            isEffective: item.isEffective,
            sectionName: item.sectionName,
            invalidityCause: item.invalidityCause,
            playProgress: `${Math.floor((item.playProgress % (1000 * 60 * 60)) / (1000 * 60))}分${Math.floor((item.playProgress % (1000 * 60)) / 1000)}秒`
          }
          if (item.playProgress === 0) {
            rtnObj.playProgress = `已完播`
          } else if (item.playProgress < 2000) {
            if (Math.floor(item.playProgress / 1000) === 1) {
              rtnObj.playProgress = `刚开始看`
            }
          }
          return rtnObj
        })
        this.total = res.totalSize
        this.isLoading = false
      },
      handleCurrentChange(page) {
        this.currentPage = page
        this.loadData()
      },
      alertMesg(item) {
        this.$message({
          message: `抱歉,该课程${getEffectiveTypeStatus(item.invalidityCause)},请选择其他课程观看`,
          type: 'warning'
        })
      },
      routerToCaseDetail(params) {
        let id = params.courseId || -1
        const routeUrl = this.$router.resolve({
          path: '/audit/caseDetails',
          query: {
            id: id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
</script>

<style lang="less" scoped>
@import '~@/views/account/less/accountCourse.less';
</style>
