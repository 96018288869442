import { request } from '@/utils/axios'

export function getMyCourses(parameter) {
  return request({
    url: '/api/v1/personalcenter/userCourseWatchRecords/detail',
    method: 'get',
    params: parameter,
    verifyToken: true
  })
}
